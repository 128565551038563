<template>

  <section class="container h-screen mx-auto flex">
    <div class="flex flex-col mx-auto justify-center p-6 text-center">
      <img src="https://news.travel/img/news-travel.c2ab999f.png" class="w-56 mx-auto"/>
      <p class="my-8 text-lg md:text-xl lg:text-2xl font-medium">
        Bridging the gap between storytelling and booking
      </p>
      <button @click="$router.push('/dashboard')" class="mx-auto mt-4 py-3 lg:py-4 px-10 lg:px-12 text-lg md:text-xl font-semibold rounded-lg shadow-md bg-white text-gray-900 border border-gray-900 hover:border-transparent hover:text-white hover:bg-gray-900 focus:outline-none">
        Get Started
      </button>
    </div>
  </section>

</template>

<script>
export default {
  name: 'Landing'
}
</script>
