<template>

  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full grid grid-cols-1 place-content-center">
    <div class="relative top-0 mx-auto border w-11/12 sm:w-4/5 lg:w-1/2 xl:w-1/3 shadow-lg rounded-md bg-white">

      <div v-if="stage == 'updated'" class="mt-3 text-center">
        <div class="mx-auto flex items-center justify-center h-12 w-12 mt-4 mb-2 rounded-full bg-green-400">
          <svg class="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" ></path>
          </svg>
        </div>
        <h3 class="text-xl leading-6 font-medium text-gray-900">Successful!</h3>
        <div class="mt-2 px-7 py-3">
          <div class="text-lg text-gray-500 overflow-scroll" style="max-height: 60vh;">
            Your property has been successfully updated!
          </div>
        </div>
        <div class="items-center px-4 py-3">
          <button @click="$emit('toggle-modal', false)" class="px-4 py-2 bg-green-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-900 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300">
            Close
          </button>
        </div>
      </div>

      <div v-else class="mt-3 mb-3 relative">
        <button @click="$emit('toggle-modal', false)" class="absolute right-0 top-0 mt-1 mr-5 text-xl opacity-50 hover:opacity-100">
          <i class="uil uil-multiply"></i>
        </button>
        <h3 class="text-lg px-5 pt-1 pb-2 border-b font-medium text-gray-900">{{name}}</h3>

        <div class="px-5 py-5 text-sm text-gray-500 overflow-scroll" style="max-height: 80vh;">
          <div class="relative upload-example overflow-hidden mb-2">

            <div v-if="image.src" class="mx-auto" style="width: 100%; height: auto;">
              <div style="position: relative; width: 100%; padding-top: -75%; position: relative; overflow: hidden" class="bg-gray-100">
                <cropper ref="cropper" :src="image.src" :resize-image="{ wheel: false }" :stencil-props="{}" :auto-zoom="true" stencil-component="circle-stencil" image-restriction="stencil" @change="updateFile"/>
                <div class="text-white absolute bottom-0 right-0 z-10 text-xl text-center m-2 pt-0.5 pr-0.5 bg-black opacity-75 hover:opacity-100 rounded-full w-8 h-8 cursor-pointer" title="Clear" @click="reset()">
                  <i class="uil uil-trash-alt"></i>
                </div>
              </div>
            </div>

            <div v-if="!image.src" class="text-center relative mx-auto leading-6 mb-2">
              <button class="upload-button avatar" @click="$refs.file.click()">
                <input ref="file" type="file" accept="image/x-png,image/gif,image/jpeg" @change="loadImage($event)" />
                <img v-if="avatar" :src="avatar + '&width=150&height=150&quality=60'" class="rounded-full shadow bg-gray-100 w-24 h-24"/>
                <img v-else :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + property.name" class="rounded-full shadow bg-gray-100 w-24 h-24"/>
                <span class="text-xs">click to edit</span>
              </button>
            </div>
          </div>

          <div class="mb-2">
            <input v-model="property.name" placeholder="Name" class="flex-grow px-3 py-2 text-lg bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100"/>
          </div>

          <div class="mb-2">
            <input v-model="property.website" placeholder="Website" class="flex-grow px-3 py-2 text-lg bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100"/>
          </div>

          <button @click="handleUpdate" class="px-4 py-2 bg-green-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-900 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300" >
            Update
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import api from '../api'
import { mapActions } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

function getMimeType (file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47': return 'image/png'
    case '47494638': return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8': return 'image/jpeg'
    default: return fallback
  }
}

export default {
  name: 'UpdateProperty',
  components: {
    Cropper
  },
  props: ['property'],
  data () {
    return {
      name: '',
      avatar: null,
      stage: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      image: {
        src: null,
        type: null
      },
      file: null,
      filename: ''
    }
  },
  async mounted () {
    this.name = this.property.name
    if (this.property.avatarId) {
      this.avatar = api.fetchImage(this.property.avatarId)
    }
  },
  methods: {
    ...mapActions(['updateProperty', 'deleteProperty', 'addAvatar', 'deleteAvatar']),
    slugify (string) {
      var slug = ''
      var titleLower = string.toLowerCase()
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      slug = slug.replace(/đ/gi, 'd')
      slug = slug.replace(/\s*$/g, '')
      slug = slug.replace(/\s+/g, '-')
      return slug
    },
    reset () {
      this.image = {
        src: null,
        type: null
      }
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    loadImage (event) {
      const { files } = event.target
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(files[0])
        this.filename = files[0].name
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          }
        }
        reader.readAsArrayBuffer(files[0])
      }
    },
    updateFile () {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      this.previewImage = canvas.toDataURL()
      canvas.toBlob((blob) => {
        const filename = this.slugify(this.property.name) + '.' + this.filename.substr(this.filename.lastIndexOf('.') + 1)
        this.file = new File([blob], filename, { type: blob.type })
      }, this.image.type)
    },
    handleDelete (property) {
      this.deleteProperty(this.property.$id)
    },
    async handleUpdate (property) {
      if (this.file !== null) {
        const read = ['role:all']
        const write = this.property.$write
        const file = this.file
        if (this.property.avatarId !== null) {
          console.log('Delete current avatar')
          this.deleteAvatar({
            fileId: this.property.avatarId
          })
        }
        const avatarId = await this.addAvatar({
          file,
          read: read,
          write: write
        })
        const data = {
          name: this.property.name,
          avatarId: avatarId
        }
        const payload = {
          data: data,
          documentId: this.property.$id,
          read: this.property.$read,
          write: this.property.$write
        }
        this.updateProperty(payload)
      } else {
        const data = {
          name: this.property.name
        }
        const payload = {
          data: data,
          documentId: this.property.$id,
          read: this.property.$read,
          write: this.property.$write
        }
        this.updateProperty(payload)
      }

      this.stage = 'updated'
    }
  },
  destroyed () {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  }
}
</script>
