<template>
  <section class="container min-h-screen mx-auto flex">
    <div class="flex-grow flex flex-col justify-center p-6">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 content-center">
        <router-link to="/stories" class="text-center bg-white p-8 pt-10 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">Stories</router-link>
        <router-link to="/properties" class="text-center bg-white p-8 pt-10 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">Properties</router-link>
        <router-link to="/widgets" class="text-center bg-white p-8 pt-10 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">Widgets</router-link>
        <router-link to="/api" class="text-center bg-white p-8 pt-10 border-dashed border-2 rounded-lg cursor-not-allowed">Analytics</router-link><br/>

        <router-link to="/api" class="text-center text-gray-400 bg-white p-8 pt-10 border-dashed border-2 rounded-lg cursor-not-allowed">Marketing Tools</router-link>
        <router-link to="/api" class="text-center text-gray-400 bg-white p-8 pt-10 border-dashed border-2 rounded-lg cursor-not-allowed">Academy</router-link>
        <router-link to="/api" class="text-center text-gray-400 bg-white p-8 pt-10 border-dashed border-2 rounded-lg cursor-not-allowed">Developers</router-link>
        <router-link to="/api" class="text-center text-gray-400 bg-white p-8 pt-10 border-dashed border-2 rounded-lg cursor-not-allowed">Help</router-link>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'Dashboard'
}
</script>
