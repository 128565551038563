import api from '../../api'
import { Server } from '../../utils/config'

const state = {
  categories: []
}

const actions = {
  fetchCategories: async ({ commit }) => {
    try {
      const categories = await api.listDocuments(Server.categoriesCollectionID)
      commit('setCategories', categories)
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to Fetch Categories',
          color: 'red'
        },
        { root: true }
      )
    }
  }
}

const getters = {
  categories: (state) => state.categories
}

const mutations = {
  setCategories: (state, categories) => (state.categories = categories.documents)
}

export default {
  state,
  actions,
  getters,
  mutations
}
