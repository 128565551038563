import api from '../../api'
import { Server } from '../../utils/config'

const state = {
  types: []
}

const actions = {
  fetchTypes: async ({ commit }) => {
    try {
      const types = await api.listDocuments(Server.typesCollectionID)
      commit('setTypes', types)
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to Fetch Types',
          color: 'red'
        },
        { root: true }
      )
    }
  }
}

const getters = {
  types: (state) => state.types
}

const mutations = {
  setTypes: (state, types) => (state.types = types.documents)
}

export default {
  state,
  actions,
  getters,
  mutations
}
