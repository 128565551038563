import { Appwrite, Query } from 'appwrite'
import { Server } from '../utils/config'

const api = {
  sdk: null,

  provider: () => {
    if (api.sdk) return api.sdk
    const appwrite = new Appwrite()
    appwrite.setEndpoint(Server.endpoint).setProject(Server.project)
    api.sdk = appwrite
    return appwrite
  },

  createAccount: (email, password, name) => {
    return api.provider().account.create('unique()', email, password, name)
  },

  getAccount: () => {
    return api.provider().account.get()
  },

  createSession: (email, password) => {
    return api.provider().account.createSession(email, password)
  },

  deleteCurrentSession: () => {
    return api.provider().account.deleteSession('current')
  },

  createDocument: (collectionId, data, read, write) => {
    return api
      .provider()
      .database.createDocument(collectionId, 'unique()', data, read, write)
  },

  listDocuments: (collectionId) => {
    return api
      .provider()
      .database.listDocuments(collectionId, '', 100, 0, '', '')
    // return api
    //  .provider()
    //   .database.listDocuments(collectionId, '', 100, 0, '', '', ['name'], ['ASC'], ['string'])
  },

  listMyDocuments: (collectionId, user) => {
    const queries = []
    queries.push(Query.equal('belongsTo', user))
    console.log(queries)
    return api
      .provider()
      .database.listDocuments(collectionId, queries, 100, 0, '', '', ['name'], ['ASC'], ['string'])
  },

  listDocumentsFiltered: (collectionId, queries) => {
    // Important: fields used in query must have an index assigned in Appwrite DB console
    return api
      .provider()
      .database.listDocuments(collectionId, queries, 100, 0, '', '', ['createdAt'], ['DESC'], ['string'])
  },

  getDocument: (collectionId, documentId) => {
    return api
      .provider()
      .database.getDocument(collectionId, documentId)
  },

  updateDocument: (collectionId, documentId, data, read, write) => {
    return api
      .provider()
      .database.updateDocument(collectionId, documentId, data, read, write)
  },

  deleteDocument: (collectionId, documentId) => {
    return api
      .provider()
      .database.deleteDocument(collectionId, documentId)
  },

  createFile: (file, read, write) => {
    return api
      .provider()
      .storage.createFile('unique()', file, read, write)
  },

  fetchFile: (fileId) => {
    return api
      .provider()
      .storage.getFile(fileId)
  },

  fetchImage: (fileId) => {
    return api
      .provider()
      .storage.getFilePreview(fileId)
  },

  downloadFile: (fileId) => {
    return api
      .provider()
      .storage.getFileDownload(fileId)
  },

  deleteFile: (fileId) => {
    return api
      .provider()
      .storage.deleteFile(fileId)
  }
}

export default api
