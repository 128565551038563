import api from '../../api'
import { Server } from '../../utils/config'

const state = {
  properties: []
}

const actions = {
  fetchProperties: async ({ commit }, { user }) => {
    try {
      const properties = await api.listMyDocuments(Server.propertiesCollectionID, user.$id)
      commit('setProperties', properties)
    } catch (e) {
      console.log(e.message)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to Fetch Properties',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  addProperty: async ({ commit }, { data, read, write }) => {
    try {
      const response = await api.createDocument(
        Server.propertiesCollectionID,
        data,
        read,
        write
      )
      commit('addProperty', response)
      return true
    } catch (e) {
      console.log(e.message)
      commit(
        'setError',
        {
          show: true,
          message: 'Invalid property: missing fields',
          color: 'red'
        },
        { root: true }
      )
      return false
    }
  },
  updateProperty: async ({ commit }, { documentId, data, read, write }) => {
    try {
      const response = await api.updateDocument(
        Server.propertiesCollectionID,
        documentId,
        data,
        read,
        write
      )
      commit('updateProperty', response)
    } catch (e) {
      console.log('Could not update document', e)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to update property',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  addAvatar: async ({ commit }, { file, read, write }) => {
    try {
      const response = await api.createFile(
        file,
        read,
        write
      )
      return response.$id
    } catch (e) {
      return false
    }
  },
  deleteAvatar: async ({ commit }, { fileId }) => {
    try {
      const response = await api.deleteFile(
        fileId
      )
      return response
    } catch (e) {
      console.log('2')
      console.log(e)
      return false
    }
  },
  deleteProperty: async ({ commit }, documentId) => {
    try {
      await api.deleteDocument(Server.propertiesCollectionID, documentId)
      commit('deleteProperty', documentId)
    } catch (e) {
      console.log('error:')
      console.log(e)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to delete property',
          color: 'red'
        },
        { root: true }
      )
    }
  }
}

const getters = {
  properties: (state) => state.properties
}

const mutations = {
  setProperties: (state, properties) => (state.properties = properties.documents),
  addProperty: (state, properties) => state.properties.unshift(properties),
  deleteProperty: (state, id) => (state.properties = state.properties.filter((property) => property.$id !== id)),
  updateProperty: (state, updatedProperty) => {
    const index = state.properties.findIndex(
      (property) => property.$id === updatedProperty.$id
    )
    if (index !== -1) {
      state.properties.splice(index, 1, updatedProperty)
    }
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
