<template>
  <section class="container min-h-screen mx-auto flex">
    <div class="flex-grow flex flex-col justify-center p-6">

      <h1 class="text-2xl md:text-3xl lg:text-4xl font-bold">Properties</h1>

      <button @click="$router.push('/dashboard')" class="flex items-center hover:underline">
        <div class="pr-1 opacity-50">
          <i class="text-xl uil uil-arrow-left"></i>
        </div>
        <div>Return to dashboard</div>
      </button>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8">
        <router-link to="/property/add" class="bg-white p-8 pt-10 border-dashed border-2 rounded-lg opacity-60 hover:opacity-100 text-center transition duration-200 ease-in-out transform hover:scale-105">Add a new Property +</router-link>
        <property v-for="property in sortedProperties" :key="property.$id" :property="property"/>
      </div>

      <update-property v-if="modal" :property="property" v-on:toggle-modal="modal = $event"/>

    </div>
  </section>

</template>

<script>
import { mapGetters } from 'vuex'
import Property from '../components/Property.vue'
import UpdateProperty from '../components/UpdateProperty.vue'

export default {
  name: 'Properties',
  components: {
    Property,
    UpdateProperty
  },
  data: function () {
    return {
      property: {},
      modal: false,
      sorted: []
    }
  },
  methods: {
    sort () {
      this.sorted = this.properties.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    handleUpdate (property) {
      this.property = property
      this.modal = !this.modal
    }
  },
  computed: {
    ...mapGetters(['properties']),
    sortedProperties: function () {
      this.sort()
      return this.sorted
    }
  }
}
</script>
