import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import categories from './modules/categories'
import posts from './modules/posts'
import types from './modules/types'
import properties from './modules/properties'
import widgets from './modules/widgets'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {},
    userData: []
  },
  getters: {
    getError: (state) => state.error
  },
  mutations: {
    setUserData (state, data) {
      state.userData = data
    },
    setError (state, error) {
      state.error = error
    }
  },
  actions: {
  },
  modules: {
    account,
    categories,
    posts,
    properties,
    types,
    widgets
  }
})
