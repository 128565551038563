<template>
  <div id="app">

    <section v-if="$route.name !== 'Embed' && $route.name !== 'Story'" class="fixed h-screen w-screen flex" style="z-index: -100">
      <div class="flex-grow flex flex-col justify-center border">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 1440 649.8986">
          <defs>
            <linearGradient id="linear-gradient" x1="1012.8741" y1="-367.9786" x2="895.1499" y2="-1020.2027" gradientTransform="matrix(-0.9763, -0.2163, 0.2163, -0.9763, 1559.9884, -130.8137)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#645938" stop-opacity="0.06"/>
              <stop offset="1" stop-color="#645938" stop-opacity="0.01"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="988.5842" y1="-468.6965" x2="876.6394" y2="-1088.9007" gradientTransform="matrix(-0.9757, -0.2191, 0.2191, -0.9757, 1578.5743, -186.583)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#645938" stop-opacity="0"/>
              <stop offset="0.3836" stop-color="#645938" stop-opacity="0.0582"/>
              <stop offset="0.9256" stop-color="#645938" stop-opacity="0.1068"/>
              <stop offset="1" stop-color="#645938" stop-opacity="0.12"/>
            </linearGradient>
          </defs>
          <g id="back">
            <path d="M1422.74,595.14c-132.09,79.874-312.9638,34.7436-412.1424,13.9061-209.8851-44.097-218.7329-95.2-383.3068-172.0173-147.6833-68.933-200.1851-70.49-645.4227,48.1057-356.5145,94.9632-442.4267,19.7813-492.4211-22.2121-85.5182-71.8323-99.94-218.9273-28.4741-272.579C-440.9562,116.7186-263.26,280.7052,55.62,237.9,218.766,216,223.7055,207.8508,369.8891,200.448c213.7516-10.8247,263.9647,132.6563,491.9325,96.8467,398.9882-62.6735,597.625-500.8422,675.2822-14.448C1551.0286,370.0611,1547.1262,519.9245,1422.74,595.14Z" fill="url(#linear-gradient)"/>
            <path d="M1459.4827,568.6256c-132.3351,79.505-330.87-5.9079-429.9842-27.0221C819.7489,496.921,829.7145,376.87,646.72,368.5173,417.4441,358.0516,340.1115,497.6982,114.1294,572.3737c-349.7676,115.58-555.4661-139.0059-605.3315-181.1386C-576.5,319.1645-590.4706,172.03-518.84,118.5781c98.2965-73.3507,309.9733,225.2465,628.9851,183.3312,163.2128-21.4447,144.7067-165.385,290.913-172.38,213.7846-10.2281,250.6606,119.303,480.62,99.909,372.6871-31.4311,616.8686-259.4448,675.3265-12.5631C1577.3645,302.8565,1584.0993,493.7577,1459.4827,568.6256Z" fill="url(#linear-gradient-2)"/>
          </g>
        </svg>
      </div>
    </section>

    <div v-if="$route.name !== 'Embed' && $route.name !== 'Story'" class="fixed w-full z-10 px-4 xl:px-12 bg-green-800">
      <div v-if="getAccount" class="flex justify-between">
        <div>
          <button @click="$router.push('/dashboard')" class="flex items-center h-8 my-4">
            <img src="@/assets/NewsTravelLogo.png" class="h-6 mr-3"/>
            <img src="@/assets/NewsTravelText.png" class="h-5"/>
          </button>
        </div>
        <div>
          <button @click="$router.push('/dashboard')" class="flex items-center h-8 my-4 text-white">
            <div class="leading-4 text-right">
              {{getAccount.name}}<br/>
              <a href="/logout" @click.prevent="logout" class="underline text-sm opacity-80">logout</a>
            </div>
            <!--
            <img :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + getAccount.name" class="inline-block w-8 h-8 ml-3 bg-gray-300 rounded-full"/>
            -->
            <div class="ml-6 pt-2">
              <a href="#" class="inline-block border border-white rounded-md"><img :src="'https://app.news.travel/v1/avatars/flags/gb?project=60e70fa53301e'" class="w-6 h-6 p-0.5 rounded-md"/></a>
              <a href="#" class="inline-block border border-transparent hover:border-white rounded-md cursor-not-allowed ml-2"><img :src="'https://app.news.travel/v1/avatars/flags/fr?project=60e70fa53301e'" class="w-6 h-6 p-0.5 rounded-md"/></a>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div :class="$route.name !== 'Embed' && $route.name !== 'Story' ? 'pt-16' : ''">
      <alert v-if="getError['show']" :message="getError['message']" :color="getError['color']" />
      <router-view/>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from './components/Alert.vue'

export default {
  components: { Alert },
  name: 'App',
  data () {
    return {
      show: ''
    }
  },
  computed: mapGetters(['getAccount', 'properties', 'types', 'categories', 'getError']),
  watch: {
    getAccount (newValue, oldValue) {
      if (newValue === null) {
        this.$router.replace('/')
      } else {
        const user = this.getAccount
        this.fetchProperties({ user })
        if (this.$route.query.return) {
          this.$router.replace(this.$route.query.return)
        } else {
          if ((oldValue == null && newValue.$id) && (this.$router.currentRoute.name !== 'Embed') && (this.$router.currentRoute.name !== 'Story') && (this.$router.currentRoute.name !== 'Accor')) {
            this.$router.replace('/dashboard')
          }
        }
      }
    }
  },
  methods: mapActions(['fetchAccount', 'fetchCategories', 'fetchProperties', 'fetchTypes', 'logout']),
  created () {
    if (!this.getAccount) {
      this.fetchAccount()
      this.fetchProperties()
      this.fetchTypes()
      this.fetchCategories()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
