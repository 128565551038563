import api from '../../api'
import { Server } from '../../utils/config'

const state = {
  widget: {},
  widgets: []
}

const actions = {
  fetchWidgets: async ({ commit }) => {
    try {
      const widgets = await api.listDocuments(Server.widgetsCollectionID)
      commit('setWidgets', widgets)
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to Fetch Widgets',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  fetchWidget: async ({ commit }, documentId) => {
    try {
      const widget = await api.getDocument(Server.widgetsCollectionID, documentId)
      commit('setWidget', widget)
      return true
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to Fetch Widgets',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  addWidget: async ({ commit }, { data, read, write }) => {
    try {
      const response = await api.createDocument(
        Server.widgetsCollectionID,
        data,
        read,
        write
      )
      commit('addWidget', response)
      return true
    } catch (e) {
      console.log(e.message)
      commit(
        'setError',
        {
          show: true,
          message: 'Invalid widget: missing fields',
          color: 'red'
        },
        { root: true }
      )
      return false
    }
  },
  updateWidget: async ({ commit }, { documentId, data, read, write }) => {
    try {
      const response = await api.updateDocument(
        Server.widgetsCollectionID,
        documentId,
        data,
        read,
        write
      )
      commit('updateWidget', response)
      commit(
        'setError',
        {
          show: true,
          message: 'Widget saved',
          color: 'emerald'
        },
        { root: true }
      )
      return true
    } catch (e) {
      console.log('Could not update document', e)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to update widget',
          color: 'red'
        },
        { root: true }
      )
      return false
    }
  },
  deleteWidget: async ({ commit }, documentId) => {
    try {
      await api.deleteDocument(Server.widgetsCollectionID, documentId)
      commit('deleteWidget', documentId)
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to delete Widget',
          color: 'red'
        },
        { root: true }
      )
    }
  }
}

const getters = {
  widget: (state) => state.widget,
  widgets: (state) => state.widgets
}

const mutations = {
  setWidgets: (state, widgets) => (state.widgets = widgets.documents),
  setWidget: (state, widget) => (state.widget = widget),
  addWidget: (state, widgets) => state.widgets.unshift(widgets),
  deleteWidget: (state, id) => (state.widgets = state.widgets.filter((widget) => widget.$id !== id)),
  updateWidget: (state, updatedWidget) => {
    const index = state.widgets.findIndex(
      (widget) => widget.$id === updatedWidget.$id
    )
    if (index !== -1) {
      state.widgets.splice(index, 1, updatedWidget)
    }
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
