import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import vSelect from 'vue-select'
import './assets/tailwind.css'
import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false

Vue.use(VCalendar)

Vue.component('v-select', vSelect)

Vue.filter('removeOrphans', function (value) {
  var replacement = '&nbsp;'
  return value.replace(/\s([^\s]*)$/, replacement + '$1')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
