<template>

  <router-link v-if="link === 'posts'" :to="'/stories/' + property.$id" class="bg-white p-8 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">
    <div class="flex items-center">
      <div class="flex-none w-8 mr-3">
        <img v-if="avatar" :src="avatar + '&width=150&height=150&quality=60'" class="rounded-full bg-gray-100 w-8 h-8"/>
        <img v-else :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + property.name" class="rounded-full bg-gray-100 w-8 h-8"/>
      </div>
      <div class="leading-5">
        <div>{{ property.name }}</div>
        <span class="opacity-50 text-sm">{{ property.city }}</span><br/>
      </div>
    </div>
  </router-link>

  <div v-else class="bg-white p-8 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">
    <a :href="'/property/' + property.$id" class="flex items-center" @click.prevent="handleUpdate(property)">
      <div class="flex-none w-8 mr-3">
        <img v-if="avatar != null" :src="avatar + '&width=150&height=150&quality=60'" class="rounded-full bg-gray-100 w-8 h-8"/>
        <img v-else :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + property.name" class="rounded-full bg-gray-100 w-8 h-8"/>
      </div>
      <div class="leading-5">
        <div>{{ property.name }}</div>
        <span class="opacity-50 text-sm">{{ property.city }}</span><br/>
      </div>
    </a>
    <button @click.prevent="handleDelete(property)" class=" absolute top-0 right-0 p-2 ml-2 text-lg focus:outline-none transition duration-75 ease-in-out transform opacity-25 hover:opacity-100">
      <i class="uil uil-trash-alt"></i>
    </button>
  </div>
</template>

<script>
import api from '../api'
import { mapActions } from 'vuex'

export default {
  name: 'Property',
  props: ['property', 'link'],
  data () {
    return {
      avatar: null
    }
  },
  async mounted () {
    if (this.property.avatarId) {
      this.avatar = api.fetchImage(this.property.avatarId)
    }
  },
  watch: {
    property: {
      handler (avatarId) {
        if (this.property.avatarId) {
          this.avatar = api.fetchImage(this.property.avatarId)
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['deleteProperty', 'deleteAvatar']),
    handleUpdate (property) {
      this.$parent.handleUpdate(property)
    },
    handleDelete (property) {
      if (confirm('Are you sure you want to delete \'' + property.name + '\'?')) {
        if (property.avatarId) {
          this.deleteAvatar(property.avatarId)
        }
        this.deleteProperty(property.$id)
      }
      return false
    }
  }
}
</script>
