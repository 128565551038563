import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import Dashboard from '../views/Dashboard.vue'
import Properties from '../views/Properties.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/accor',
    name: 'Accor',
    component: () => import(/* webpackChunkName: "about" */ '../views/Accor.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/embed/:widget_id',
    name: 'Embed',
    component: () => import(/* webpackChunkName: "embed" */ '../views/Embed.vue')
  },
  {
    path: '/feed/:feed_id',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "feed" */ '../views/Feed.vue')
  },
  {
    path: '/properties',
    name: 'Properties',
    component: Properties,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/property/add',
    name: 'AddProperty',
    component: () => import(/* webpackChunkName: "addproperty" */ '../views/AddProperty.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stories',
    name: 'Stories',
    component: () => import(/* webpackChunkName: "stories" */ '../views/Stories.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stories/:property_id',
    name: 'StoriesByProperty',
    component: () => import(/* webpackChunkName: "storiesbyproperty" */ '../views/StoriesByProperty.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/story/:story_id',
    name: 'Story',
    component: () => import(/* webpackChunkName: "story" */ '../views/Story.vue')
  },
  {
    path: '/widgets',
    name: 'Widgets',
    component: () => import(/* webpackChunkName: "widgets" */ '../views/Widgets.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/widget/add',
    name: 'AddWidget',
    component: () => import(/* webpackChunkName: "widget" */ '../views/AddWidget.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/widget/:widget_id',
    name: 'Widget',
    component: () => import(/* webpackChunkName: "widget" */ '../views/Widget.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.getAccount) {
      next()
    } else {
      next({ name: 'Login', query: { return: to.path } })
    }
  } else {
    next()
  }
})

export default router
