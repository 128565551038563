import api from '../../api'
import { Server } from '../../utils/config'

const state = {
  posts: []
}

const actions = {
  fetchPosts: async ({ commit }, { queries }) => {
    try {
      const posts = await api.listDocumentsFiltered(Server.postsCollectionID, queries)
      commit('setPosts', posts)
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to Fetch Posts',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  addPost: async ({ commit }, { file, video, data, read, write }) => {
    try {
      const response = await api.createFile(
        file,
        read,
        write
      )
      data.image = response.$id

      if (video) {
        const responseVideo = await api.createFile(
          video,
          read,
          write
        )
        data.video = responseVideo.$id
      }

      try {
        const response = await api.createDocument(
          Server.postsCollectionID,
          data,
          read,
          write
        )
        commit('addPost', response)
        return true
      } catch (e) {
        console.log('Error message:')
        console.log(e.message)
        commit(
          'setError',
          {
            show: true,
            message: 'Invalid post: missing fields',
            color: 'red'
          },
          { root: true }
        )
        return false
      }
    } catch (e) {
      console.log(e.message)
      commit(
        'setError',
        {
          show: true,
          message: e.message,
          color: 'red'
        },
        { root: true }
      )
      return false
    }
  },
  updatePost: async ({ commit }, { documentId, data, read, write }) => {
    try {
      const response = await api.updateDocument(
        Server.postsCollectionID,
        documentId,
        data,
        read,
        write
      )
      commit('updatePost', response)
    } catch (e) {
      console.log('Could not update document', e)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to update property',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  deletePost: async ({ commit }, documentId) => {
    try {
      await api.deleteDocument(Server.postsCollectionID, documentId)
      commit('deletePost', documentId)
    } catch (e) {
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to delete Todo',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  fetchFile: async ({ commit }, fileId) => {
    try {
      console.log('Fetching: ' + fileId)
      // await api.fetchFile(fileId)
    } catch (e) {
      console.log(e.message)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to get file',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  deleteFile: async ({ commit }, fileId) => {
    try {
      await api.deleteFile(fileId)
    } catch (e) {
      console.log(e.message)
      commit(
        'setError',
        {
          show: true,
          message: 'Failed to delete file',
          color: 'red'
        },
        { root: true }
      )
    }
  },
  clearPosts: async ({ commit }) => {
    commit('clearPosts')
  }
}

const getters = {
  posts: (state) => state.posts
}

const mutations = {
  setPosts: (state, posts) => (state.posts = posts.documents),
  addPost: (state, posts) => state.posts.unshift(posts),
  deletePost: (state, id) => (state.posts = state.posts.filter((property) => property.$id !== id)),
  updatePost: (state, updatedPost) => {
    const index = state.posts.findIndex(
      (property) => property.$id === updatedPost.$id
    )
    if (index !== -1) {
      state.posts.splice(index, 1, updatedPost)
    }
  },
  clearPosts: (state) => (state.posts = [])
}

export default {
  state,
  actions,
  getters,
  mutations
}
